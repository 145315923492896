// components
import Item from 'components/footer/Item';
import MoveBtn from 'components/footer/MoveBtn';
import Wrapper from 'components/footer/Wrapper';


const Footer = ({ title, nowPage, totalPage, onPageMove }: Props) => {
  return (
    <div>
      <Wrapper>
            <MoveBtn type="PREV" onClick={() => onPageMove("PREV")} />
            <Item text={title} />
            {/* <Item text={`${nowPage} / ${totalPage}`} /> */}
            <MoveBtn type="NEXT" onClick={() => onPageMove("NEXT")} />
      </Wrapper>
    </div>
    
  );
}

interface Props {
  title: string;
  nowPage: number;
  totalPage: number;
  onPageMove: (type: "PREV" | "NEXT") => void;
}

export default Footer