// components
import ControlBtn from 'components/header/ControlBtn'
import Layout, { AutoLayout } from 'components/header/Layout'
import Logo from 'components/header/Logo'
import Wrapper from 'components/header/Wrapper'

const Header = ({
  onNavToggle, 
  onOptionToggle, 
  onLearningToggle
}: Props) => {
  return (
    <Wrapper>
      <Layout>
        <AutoLayout>
          <Logo />
          <div style={{paddingRight:'2.2rem' }}>
            <ControlBtn message="অধ্যায়" onClick={onNavToggle} />
            <ControlBtn message="সেটিংস" onClick={onOptionToggle} />
            {/* <ControlBtn message="Highlights" onClick={onLearningToggle} /> */}
           
          </div>
        </AutoLayout>
      </Layout>
    </Wrapper>
  );
}

interface Props {
  onNavToggle: (value?: boolean) => void;
  onOptionToggle: (value?: boolean) => void;
  onLearningToggle: (value?: boolean) => void;
}

export default Header