import BookReader from 'Pages/BookReader';
import Home from 'Pages/Home';
import Preview from 'Pages/Preview';
import {
  BrowserRouter, Route, Routes
} from "react-router-dom";
const App = () => {
    return (
        <div>
            <BrowserRouter>
              <Routes>
                  <Route path="/" element={<Home/>} />
                  <Route path="reader/*" element={<BookReader />} />
                  <Route path="preview/:id" element={<Preview />} />
              </Routes>
          </BrowserRouter>
        </div>
    );
};

export default App;